import { Hero } from "components/Hero";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";
import { store } from "stores/store";

/**
 * @param {React.MouseEvent} e
 * @param {"" | "general" | "booth8" | "booth12" | "cocktailBooth"} ticketShop
 */
function openTicketShop(e, ticketShop = "") {
  store.preferredTicketShop = ticketShop;
  if (matchMedia("(min-width: 885px)").matches) {
    e.preventDefault();
    store.openSidebar();
  }
}

export default function VipPage({ location }) {
  const title = "Club VIP Style | Plush Oxford";
  const description =
    "Celebrating? Special occasion? Or just because you want to!!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero background={<StaticImage src="../images/heroVip.jpeg" alt="" />}>
        <h1>Club VIP Style</h1>
      </Hero>
      <div className="vip__content">
        <section className="vip__section">
          <h2>Club VIP Style</h2>
          <p>Celebrating? Special occasion? Or just because you want to!!</p>
          <p>
            Why not book one of our VIP Booths and get entry, queue jump, vip
            access and your own private VIP Booth included for up to 8 or 12
            people.
          </p>
          <p>
            Wanna really do it in style? Add a drinks package to your booking
            and save even more!
          </p>
        </section>
        <section className="vip__section">
          <h2>Choose Your Booth</h2>
          <Link
            to="/events"
            className="vip__button"
            onClick={(e) => openTicketShop(e, "general")}
          >
            Book now
          </Link>
          <ul className="vip__figure_list">
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipZebra.jpeg"
                  alt=""
                />
                <figcaption className="vip__figure_caption-filled">
                  <h4>Zebra</h4>
                  <p>For up to 8 people</p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipTiger.jpeg"
                  alt=""
                />
                <figcaption className="vip__figure_caption-filled">
                  <h4>Tiger</h4>
                  <p>For up to 8 people</p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipLeopard.jpeg"
                  alt=""
                />
                <figcaption className="vip__figure_caption-filled">
                  <h4>Leopard</h4>
                  <p>For up to 12 people</p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipCocktail.png"
                  alt=""
                />
                <figcaption className="vip__figure_caption-filled">
                  <h4>Cocktail Booth</h4>
                  <p>For up to 6 people</p>
                </figcaption>
              </figure>
            </li>
          </ul>
        </section>
        {/* <section className="vip__section">
          <h2>Choose Your Package</h2>
          <ul className="vip__figure_list">
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageFruity.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>Fruity Party</h4>
                  <p>
                    8 bottles of VK (12 for leopard) & a bottle of Apple sours
                  </p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageMexican.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>Mexican Party</h4>
                  <p>
                    8 bottles of corona (12 for Leopard) & 1 bottle of tequila
                  </p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageChampagne.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>Champagne Celebration</h4>
                  <p>2 bottles of Veuve Cliqout</p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageGold.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>Gold Spirits</h4>
                  <p>
                    Choose a bottle from our gold spirit range served with 2
                    jugs of mixer and ice
                  </p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageVodka.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>AU Vodka Package</h4>
                  <p>
                    Choose any bottle of AU Vodka served with 2 jugs of mixer
                    and ice
                  </p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackagePlatinum.jpeg"
                  alt=""
                />
                <figcaption>
                  <h4>Platinum Spirit Package</h4>
                  <p>
                    Choose a bottle from our platinum spirit range served with 2
                    jugs of mixers and ice
                  </p>
                </figcaption>
              </figure>
            </li>
            <li>
              <figure>
                <StaticImage
                  className="vip__figure_image"
                  src="../images/vipPackageCocktail.png"
                  alt=""
                />
                <figcaption>
                  <h4>Cocktail Tree</h4>
                  <p>Choose 6 of our most popular cocktails</p>
                </figcaption>
              </figure>
            </li>
          </ul>
        </section>
        <section className="vip__section">
          <Link
            to="/events"
            className="vip__button vip__button-filled"
            onClick={(e) => openTicketShop(e)}
          >
            Click here to book now
          </Link>
        </section>
        <section className="vip__section">
          <h2>VIP Pricing</h2>
          <div className="vip__pricing_columns">
            <div className="vip__pricing_legend">
              <h4>Packages</h4>
              <ul>
                <li>Fruity Party</li>
                <li>Mexican Party</li>
                <li>Champagne</li>
                <li>Gold Spirit</li>
                <li>Platinum Sprit</li>
                <li>AU Vodka</li>
                <li>Cocktail Tree</li>
              </ul>
            </div>
            <Link
              to="/events"
              className="vip__pricing_card"
              onClick={(e) => openTicketShop(e, "booth8")}
            >
              <h3>Zebra</h3>
              <p>For up to 8 people</p>
              <strong>From £120</strong>
              <div className="vip__button vip__button-filled">Select booth</div>
              <ul>
                <li>
                  <div className="vip__pricing_label" />
                  <div>
                    <span className="vip__pricing_day">Tue</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Thu</span>
                  </div>
                  <div>
                    <span className="vip__pricing_day">Fri</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Sat</span>
                  </div>
                </li>
                <li>
                  <div className="vip__pricing_label">Fruity Party</div>
                  <div>£120</div>
                  <div>£200</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Mexican Party</div>
                  <div>£120</div>
                  <div>£200</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Champagne</div>
                  <div>£140</div>
                  <div>£220</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Gold Spirit</div>
                  <div>£140</div>
                  <div>£220</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Platinum Sprit</div>
                  <div>£240</div>
                  <div>£320</div>
                </li>
                <li>
                  <div className="vip__pricing_label">AU Vodka</div>
                  <div>£340</div>
                  <div>£420</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Cocktail Tree</div>
                  <div>－</div>
                  <div>－</div>
                </li>
              </ul>
            </Link>
            <Link
              to="/events"
              className="vip__pricing_card"
              onClick={(e) => openTicketShop(e, "booth8")}
            >
              <h3>Tiger</h3>
              <p>For up to 8 people</p>
              <strong>From £120</strong>
              <div className="vip__button vip__button-filled">Select booth</div>
              <ul>
                <li>
                  <div className="vip__pricing_label" />
                  <div>
                    <span className="vip__pricing_day">Tue</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Thu</span>
                  </div>
                  <div>
                    <span className="vip__pricing_day">Fri</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Sat</span>
                  </div>
                </li>
                <li>
                  <div className="vip__pricing_label">Fruity Party</div>
                  <div>£120</div>
                  <div>£200</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Mexican Party</div>
                  <div>£120</div>
                  <div>£200</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Champagne</div>
                  <div>£140</div>
                  <div>£220</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Gold Spirit</div>
                  <div>£140</div>
                  <div>£220</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Platinum Sprit</div>
                  <div>£240</div>
                  <div>£320</div>
                </li>
                <li>
                  <div className="vip__pricing_label">AU Vodka</div>
                  <div>£340</div>
                  <div>£420</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Cocktail Tree</div>
                  <div>－</div>
                  <div>－</div>
                </li>
              </ul>
            </Link>
            <Link
              to="/events"
              className="vip__pricing_card"
              onClick={(e) => openTicketShop(e, "booth12")}
            >
              <h3>Leopard</h3>
              <p>For up to 12 people</p>
              <strong>From £140</strong>
              <div className="vip__button vip__button-filled">Select booth</div>
              <ul>
                <li>
                  <div className="vip__pricing_label" />
                  <div>
                    <span className="vip__pricing_day">Tue</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Thu</span>
                  </div>
                  <div>
                    <span className="vip__pricing_day">Fri</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Sat</span>
                  </div>
                </li>
                <li>
                  <div className="vip__pricing_label">Fruity Party</div>
                  <div>£140</div>
                  <div>£260</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Mexican Party</div>
                  <div>£140</div>
                  <div>£260</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Champagne</div>
                  <div>£140</div>
                  <div>£280</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Gold Spirit</div>
                  <div>£160</div>
                  <div>£280</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Platinum Sprit</div>
                  <div>£200</div>
                  <div>£320</div>
                </li>
                <li>
                  <div className="vip__pricing_label">AU Vodka</div>
                  <div>£300</div>
                  <div>£420</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Cocktail Tree</div>
                  <div>－</div>
                  <div>－</div>
                </li>
              </ul>
            </Link>
            <Link
              to="/events"
              className="vip__pricing_card"
              onClick={(e) => openTicketShop(e, "cocktailBooth")}
            >
              <h3>Cocktail Booth</h3>
              <p>For up to 6 people</p>
              <strong>From £90</strong>
              <div className="vip__button vip__button-filled">Select booth</div>
              <ul>
                <li>
                  <div className="vip__pricing_label" />
                  <div>
                    <span className="vip__pricing_day">Tue</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Thu</span>
                  </div>
                  <div>
                    <span className="vip__pricing_day">Fri</span>
                    <span> / </span>
                    <span className="vip__pricing_day">Sat</span>
                  </div>
                </li>
                <li>
                  <div className="vip__pricing_label">Fruity Party</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Mexican Party</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Champagne</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Gold Spirit</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Platinum Sprit</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">AU Vodka</div>
                  <div>－</div>
                  <div>－</div>
                </li>
                <li>
                  <div className="vip__pricing_label">Cocktail Tree</div>
                  <div>£90</div>
                  <div>£150</div>
                </li>
              </ul>
            </Link>
          </div>
        </section> */}
      </div>
    </>
  );
}
